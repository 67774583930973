import { hasClass, addClass, removeClass } from "../utilities/dom-toolkit";

export default class App__Search {
  constructor() {
    this.searchControl = document.querySelector("#search-control > a");
    this.searchComponent = document.querySelector("#search-component");
  }

  init() {
    const searchControl = this.searchControl;
    const searchComponent = this.searchComponent;

    if (!searchControl || !searchComponent) {
      return;
    }

    searchControl.onclick = (evt) => {
      evt.preventDefault();
      if (hasClass(searchComponent, "active")) {
        searchControl.setAttribute("aria-expanded", false);
        searchComponent.setAttribute("aria-hidden", true);
        removeClass(searchControl.parentElement, "active");
        removeClass(searchComponent, "active");
      } else {
        searchControl.setAttribute("aria-expanded", true);
        searchComponent.setAttribute("aria-hidden", false);
        addClass(searchControl.parentElement, "active");
        addClass(searchComponent, "active");
      }
    };
  }
}
