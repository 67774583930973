export default class App__A11y {
  constructor() {
    this.extLinks = document.querySelectorAll('a[target="_blank"]');
    this.lang = document.documentElement.getAttribute("lang");
    this.isFrench = this.lang == "fr";
  }

  init() {
    this.extLinks.forEach((link) => {
      // Add rel=noopener attribute
      link.setAttribute("rel", "noopener");

      // create span with show-for-sr class
      const extLinkSpan = document.createElement("span");
      extLinkSpan.classList.add("show-for-sr");

      // add text to span
      const extLinkTxt = document.createTextNode(
        this.isFrench
          ? "Le lien s'ouvre dans un nouvel onglet"
          : "link opens in new window"
      );
      extLinkSpan.appendChild(extLinkTxt);

      // append span to link
      link.appendChild(extLinkSpan);
    });
  }
}
