import { hasClass, addClass, removeClass } from "../utilities/dom-toolkit";

export default class App__MobileMenu {
  constructor() {
    this.closeMobileMenu = document.querySelector(
      ".mobile-menu .close-mobile-menu"
    );
    this.mobileMenuControl = document.querySelector("#mobile-menu-toggle");
    this.mobileMenuComponent = document.querySelector(".mobile-menu");
    this.mobileMenuCollapseFirstLevel = document.querySelectorAll(
      ".mobile-primary-nav .first-level"
    );
    this.mobileMenuCollapseSecondLevel = document.querySelectorAll(
      ".mobile-primary-nav .third-level-toggle"
    );
    this.secondLevelMenu = document.querySelectorAll(
      ".mobile-menu .second-level-menu"
    );
    this.thirdLevelMenu = document.querySelectorAll(
      ".mobile-menu .third-level-menu"
    );
    this.html = document.querySelector("html");
  }

  init() {
    this.initMenuControlClickListener();
    this.initCloseMobileMenuListener();
    this.initCollapseFirstLevel();
    this.initCollapseSecondLevel();
  }

  resetMobileMenu() {
    // Close Second Level Menus
    this.secondLevelMenu.forEach((menu) => {
      removeClass(menu, "open");
      removeClass(menu.parentElement, "isActive");
      menu.setAttribute("aria-expanded", false);
    });

    // Close Third Level Menus
    this.thirdLevelMenu.forEach((menu) => {
      removeClass(menu, "open");
      removeClass(
        menu.parentElement.querySelector(".third-level-toggle"),
        "isActive"
      );
      menu.setAttribute("aria-expanded", false);
    });
  }

  // Open First level menu onclick of Nav buttons
  initMenuControlClickListener() {
    this.mobileMenuControl.onclick = () => {
      if (hasClass(this.mobileMenuComponent, "open")) {
        this.mobileMenuComponent.setAttribute("aria-hidden", true);
        removeClass(this.mobileMenuComponent, "open");
        removeClass(this.html, "menuOpen");
      } else {
        this.mobileMenuComponent.setAttribute("aria-hidden", false);
        addClass(this.mobileMenuComponent, "open");
        addClass(this.html, "menuOpen");
      }
    };
  }

  // Close mobile menu
  initCloseMobileMenuListener() {
    if (this.closeMobileMenu) {
      this.closeMobileMenu.onclick = () => {
        this.resetMobileMenu();
        this.mobileMenuComponent.setAttribute("aria-hidden", true);
        removeClass(this.mobileMenuComponent, "open");
        removeClass(this.html, "menuOpen");
      };
    }
  }

  // Open Second level menu onclick of first level buttons
  initCollapseFirstLevel() {
    this.mobileMenuCollapseFirstLevel.forEach((element) => {
      element.onclick = function () {
        //resetMobileMenu();
        const secondLevelNav =
          element.parentElement.querySelector(".second-level-menu");
        if (hasClass(element.parentElement, "isActive")) {
          removeClass(element.parentElement, "isActive");
          removeClass(secondLevelNav, "open");
          secondLevelNav.setAttribute("aria-expanded", false);
        } else {
          addClass(element.parentElement, "isActive");
          addClass(secondLevelNav, "open");
          secondLevelNav.setAttribute("aria-expanded", true);
        }
        //let secondLevelNav = element.parentElement.querySelector('.second-level-menu');
        //addClass(secondLevelNav, "open");
      };
    });
  }

  // Open Third level menu onclick of second level buttons
  initCollapseSecondLevel() {
    this.mobileMenuCollapseSecondLevel.forEach((element) => {
      element.onclick = () => {
        const thirdLevelNav =
          element.parentElement.querySelector(".third-level-menu");
        if (hasClass(thirdLevelNav, "open")) {
          removeClass(element, "isActive");
          removeClass(element.parentElement, "isActive");
          removeClass(thirdLevelNav, "open");
          thirdLevelNav.setAttribute("aria-expanded", false);
        } else {
          addClass(element, "isActive");
          addClass(element.parentElement, "isActive");
          addClass(thirdLevelNav, "open");
          thirdLevelNav.setAttribute("aria-expanded", true);
        }
      };
    });
  }
}
