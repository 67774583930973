/*
 * 3rd Party Dependencies
 * =========================================================================== */

/*
 * Custom Dependencies
 * =========================================================================== */

// Import Critical JS Components
import App__Navigation from "./components/Navigation";
import App__Search from "./components/Search";
import App__MobileMenu from "./components/MobileMenu";
import App__A11y from "./components/A11y";

// Import Critical JS Utilities
// import App__responsiveImage from "./utilities/responsive-image";

/*
 * Setup the global App object
 * =========================================================================== */
let App = (window.App = {});

// Add Critical JS Components to App
App.Navigation = new App__Navigation();
App.Search = new App__Search();
App.MobileMenu = new App__MobileMenu();
App.A11y = new App__A11y();

// Add Critical JS Utils to App
App.utils = {};

// Media queries
App.mediaQueries = {
  lg: "screen and (min-width: 992px)",
  isLargeUp: "",
};
// Setup media query and listener for large screens
const mediaQueryList__lg = window.matchMedia(App.mediaQueries.lg);
// Call listener function explicitly at run time
App.mediaQueries.isLargeUp = mediaQueryList__lg.matches ? true : false;
// Attach listener function to listen in on state changes
mediaQueryList__lg.addListener((mediaQuery__lg) => {
  // Screen is Large or greater
  if (mediaQueryList__lg.matches) {
    App.mediaQueries.isLargeUp = true;

    // Screen is less than Large
  } else {
    App.mediaQueries.isLargeUp = false;
  }
});

// Build Critical JS initializer
App.criticalInit = function () {
  App.Navigation.init();
  App.Search.init();
  App.MobileMenu.init();
  App.A11y.init();
};

// Initialize Critical JS
App.criticalInit();
