import {
  findParentClass,
  hasClass,
  addClass,
  removeClass,
} from "../utilities/dom-toolkit";

export default class App__Navigation {
  constructor() {
    this.firstLevelNav = document.querySelectorAll(".first-level");

    this.secondLevelNav = document.querySelectorAll(".second-level");

    this.thirdLevelNav = document.querySelectorAll(".third-level");

    this.secondLevelMenu = document.querySelectorAll(
      ".primary-nav .second-level-menu"
    );

    this.thirdLevelMenu = document.querySelectorAll(
      ".primary-nav .third-level-menu"
    );

    this.toggleThirdLevel = document.querySelectorAll(
      ".primary-nav .third-level-toggle"
    );

    this.body = document.querySelector("body");

    this.utilityNavItems = document.querySelectorAll(".utility-nav ul li a");

    this.searchControl = document.querySelector("#search-control a");
  }

  // Reset all submenus on focus of first level link by default
  resetMenu() {
    let menu;
    // Reset first level menu
    for (let i = this.firstLevelNav.length - 1; i >= 0; i--) {
      menu = this.firstLevelNav[i];
    }
    // Close Second Level Menus
    for (let i = this.secondLevelMenu.length - 1; i >= 0; i--) {
      menu = this.secondLevelMenu[i];
      removeClass(menu, "open");
    }
    // Close Third Level Menus
    for (let i = this.thirdLevelMenu.length - 1; i >= 0; i--) {
      menu = this.thirdLevelMenu[i];
      removeClass(menu, "open");
    }
    // Reset all Third Level Links Tabindex
    for (let i = this.thirdLevelNav.length - 1; i >= 0; i--) {
      const element = this.thirdLevelNav[i];
      element.setAttribute("tabindex", "-1");
    }
    // Reset all Third Menu Toggles
    for (let i = this.toggleThirdLevel.length - 1; i >= 0; i--) {
      const toggle = this.toggleThirdLevel[i];
      removeClass(toggle.parentElement, "isActive");
    }
  }

  init() {
    // Reset on last utility nav item focus or search focus
    this.utilityNavItems[this.utilityNavItems.length - 1].onfocus = () => {
      this.resetMenu();
    };

    if (this.searchControl) {
      this.searchControl.onfocus = () => {
        this.resetMenu();
      };
    }

    // Hide menu on click of body
    this.body.onclick = (event) => {
      if (hasClass(event.target, "third-level-toggle") == false) {
        this.resetMenu();
      }
    };

    this.initAllLevelsMenu();
  }

  initAllLevelsMenu() {
    this.initFirstLevelNav();
    this.initSecondLevelNav();
    this.initSecondLevelMenu();
    this.initThirdLevelNav();
    this.initToggleThirdLevel();
    this.initThirdLevelMenu();
  }

  // Loop through First level nav items
  initFirstLevelNav() {
    for (let i = this.firstLevelNav.length - 1; i >= 0; i--) {
      const element = this.firstLevelNav[i];
      let firstLevelFocused = false;
      element.onfocus = () => {
        this.resetMenu();
        if (hasClass(element.parentElement, "children")) {
          firstLevelFocused = true;
          addClass(
            element.parentElement.querySelector(".second-level-menu"),
            "open"
          );
          element.setAttribute("aria-expanded", true);
        }
      };
      element.onblur = () => {
        firstLevelFocused = false;
        element.setAttribute("aria-expanded", false);
      };
      element.onmouseover = () => {
        this.resetMenu();
        element.setAttribute("aria-expanded", true);
        if (firstLevelFocused == true) {
          this.resetMenu();
        }
      };
      element.onmouseleave = () => {
        element.setAttribute("aria-expanded", false);
      };
      // Apply active class if child page is active

      if (element.parentElement.querySelectorAll("ul .selected").length == 1) {
        addClass(element, "hasActiveChild");
      }
    }
  }

  // Loop through second level nav items and show the parent when on focus
  initSecondLevelNav() {
    for (let i = this.secondLevelNav.length - 1; i >= 0; i--) {
      const element = this.secondLevelNav[i];
      element.onfocus = () => {
        const parent = element.parentElement;
        const grandParent = parent.parentElement;
        if (findParentClass(parent, "children") == true) {
          addClass(grandParent, "open");
        }
      };
    }
  }

  // Apply id to second level menus for aria-labelledby
  initSecondLevelMenu() {
    for (let i = this.secondLevelMenu.length - 1; i >= 0; i--) {
      const navItem = this.secondLevelMenu[i];
      const navItemParentAria = navItem.parentElement
        .querySelector(".first-level")
        .getAttribute("aria-controls");
      navItem.setAttribute("id", navItemParentAria);
    }
  }

  // Keep Menu Open when sub menu is
  initThirdLevelNav() {
    for (let i = this.thirdLevelNav.length - 1; i >= 0; i--) {
      const element = this.thirdLevelNav[i];
      element.onfocus = () => {
        const parent = element.parentElement;
        const grandParent = parent.parentElement;
        const greatParent = grandParent.parentElement;
        const greatGreatParent = greatParent.parentElement;
        if (findParentClass(parent, "children") == true) {
          addClass(grandParent, "open");
        }
        if (findParentClass(parent, "children") == true) {
          addClass(greatGreatParent, "open");
        }
      };
    }
  }

  // Open up third level menu on click of "toggle" button
  initToggleThirdLevel() {
    for (let i = this.toggleThirdLevel.length - 1; i >= 0; i--) {
      const element = this.toggleThirdLevel[i];
      element.onclick = () => {
        const thirdLevelMenu =
          element.parentElement.querySelector(".third-level-menu");
        if (hasClass(thirdLevelMenu, "open")) {
          removeClass(element.parentElement, "isActive");
          removeClass(thirdLevelMenu, "open");
          element.setAttribute("aria-expanded", false);
          // Add tab index-1 to all child menu items (so keyboard users can skip hidden menus)
          for (let i = this.thirdLevelNav.length - 1; i >= 0; i--) {
            const element = this.thirdLevelNav[i];
            element.setAttribute("tabindex", "-1");
          }
        } else {
          addClass(element.parentElement, "isActive");
          addClass(thirdLevelMenu, "open");
          element.setAttribute("aria-expanded", true);

          // Remove the tab-index-1 so users can now access the child menu via keyboard
          for (let i = this.thirdLevelNav.length - 1; i >= 0; i--) {
            const element = this.thirdLevelNav[i];
            element.removeAttribute("tabindex");
          }
        }
      };

      element.onfocus = () => {
        const parent = element.parentElement;
        const grandParent = parent.parentElement;
        if (findParentClass(grandParent, "children") == true) {
          addClass(grandParent, "open");
        }
      };
    }
  }

  // Add id to third level menu for aria-controls
  initThirdLevelMenu() {
    for (let i = this.thirdLevelMenu.length - 1; i >= 0; i--) {
      const element = this.thirdLevelMenu[i];
      const navItemParentAria = element.parentElement
        .querySelector(".third-level-toggle")
        .getAttribute("aria-controls");
      element.setAttribute("id", navItemParentAria);
    }
  }
}
